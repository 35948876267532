import schttp from 'public/src/services/schttp'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { isEmpty } from 'lodash'
import { getQueryString } from '@shein/common-function'
import dayjs from 'dayjs'
import { feedbackAbtToRuleId, getFeedbackRecDataInSession, setFeedbackRecDataInSession } from '../utils/index'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
// import UserInfoManager from 'public/src/services/UserInfoManager'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

// 中间层融合相关
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'

const {  IS_RW } = gbCommonInfo
export default {
  data() {
    return {
      activePanel: null, // 当前激活的面板
      recoGoods: [], // 推荐商品列表
      recoGoodsItemInfo: null,
      recoItem: null, // 当前操作的商品
      similarRecommendData: {}, // 找相似弹窗推荐数据
      panelItemShowConfig: {
        hideGoodsName: true,
        hideOperationBtn: true,
        hidePromotionIcon: true,
        hideDiscountLabel: true,
        hideDiscountBadge: true,
        // hideNormalPrices: true,
        showOnlyNewFlashPrice: false,
        disableMainimgJump: false,
        code: 'goodsList',
        daEventExposeId: '2-3-2',
        from: 'realtime_feedback',
        ableDeleteWishlist: false,

        // showLastPrice: true,

        showDelete: false,
        showAddBagAfterGoodsPrice: false,
        showNewFlashLabelBelowPrice: false,
        showSheinClubPrice: false,
        showBestDeal: false,
        showStockLeft: false,
        saleOutText: false,
        showSpuImg: false, // spu图
        showBadge: false, // 左下角角标
        showAddToBoard: false, // 加组弹窗
        showMultiColor: false, // 色块
        showAddWishlist: false, // 收藏按钮
        showSeriesBrand: false, // 品牌/系列
        showPromotion: false, // 促销
        showVideoIcon: false, // 视频图标
        showLocalSeller: false, // 本地卖家角标
        showQuickShip: false, // 快速发货标签
        showSheinClubDiscountValue: false, // 付费会员折扣
        showNewFlashPrice: false, // 新型闪购价格
        showDiscountLabelAfterPrice: false, // 折扣标签
        showPromoLabel: false, // 大促标签
        showBestDealLabel: false, // 保留款
        showUserBehaviorLabel: false, // 用户行为标签
        showUserCommentKeywordsLabel: false, // 用户评论关键词标签
        showBeltLabel: false, // 腰带
        showRankingList: false, // 榜单
        showImgMask: false, // 图片蒙层
        showSheinClubPriceOnSale: true, // 付费会员价，占用原来的销售价
        showNewFlashPriceOnSale: true, // 新型闪购价，占用原来的销售价
        showEstimatedPriceOnSale: true, // 到手价-占用原来的销售价位置
        // showEstimatedPrice: false, // 不需要到手价
        noNeedAnyEstimatedPriceTag: true, // 到手价不需要展示任何标签
        PriceV2LabelPosition: '', // 不展示任何价格标签文案
        style: {
          salePriceColor: '#fff',
          salePriceFontSize: '12px',
        },
      },
      // 操作加车/收藏配置
      recoPanelOperationConfig: {
        isCarSuccess: false, // 是否加车成功
        isFavSuccess: false, // 是否收藏成功
      },
      activeFeedRecoRule: 0, // 当前激活的规则索引
      LastGoodsIds: '', // 上一次请求的商品id
      LastCateIds: '', // 上一次请求的分类id
      showRecoPanelTime: 0,
      usePanelBffApi: {} // 是否走BFF中间层接口
    }
  },
  created() {
    // let { EstimatedPrice } = this.listAbtResult || {}
    // const estimatedPrice = EstimatedPrice?.p?.E_Price_Cal
    // const hitABPriceAbt = ['A', 'B', 'C', 'D'].includes(estimatedPrice)
    // 处理会员价不展示问题
    const { sheinClubType, isPaid } = this.sheinClubInfo || {}
    const showOldSheinClubTag = `${sheinClubType}` === '1'
    const showNewSheinClubTag = `${sheinClubType}` === '2'
    this.panelItemShowConfig = Object.assign({}, this.panelItemShowConfig, {
      // 处理到手价
      // showEstimatedPriceReachThreshold: estimatedPrice === 'Have_Threshold' || estimatedPrice === 'Non_Threshold', // 到手价（满足门槛）
      // 处理反馈弹窗会员价问题
      showSheinClubDiscountValue: showOldSheinClubTag,
      showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
      showSheinClubPriceOnSale: isPaid,
      // 处理合规价问题（所有的都不需要展示划线价）
      standardPrice: true,
      showRetailPrice: false,
    })

  },
  async mounted() {
    this.initBffInfoFun()
    this.recoGoodsItemInfo = new GoodsItemInfo({
      listLanguage: this.language,
      getGoodsUrl: this.getGoodsUrl,
      itemConfig: this.panelItemShowConfig
    })

  },
  computed: {
    recoPanelConfig() {
      const { feedReco } = this.cccConfig || {}

      return {
        ...feedReco,
        ...(this.recommendPanelConfig || {}),
        ...(this.activeFeedRecoRule || {})
      }
    },
    // 最终展示的推荐商品数据【根据ccc配置截取展示】
    showRecoGoods() {
      if(!this.recoGoods.length || !this.recoPanelConfig?.productImpressions) return []

      return this.recoGoods.slice(0, this.recoPanelConfig.productImpressions || 0)
    }
  },
  methods: {
    initBffInfoFun() {
      const bffKeyTable = [
        'recomPop_v1', // 反馈弹窗用
      ]
      this.usePanelBffApi = isSwitchBffApiVersion(bffKeyTable)
    },
    getRecoPanelRefName(item) {
      return `recommendPanel_${item.goods_id}`
    },
    getShowSimilarPopup(item) {
      return !!this.similarRecommendData?.[item.goods_id]?.length || !!item?.similar_products?.length
    },
    getRecoPanel(item) {
      return this.$refs[this.getRecoPanelRefName(item)]
    },
    // 获取缓存key
    /**
     * 
     * @returns 
     */
    getRecoPanelStorageKey(){
      // const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'RECOMMENDPOP/GETKEY' })
      // const userKey = memberId ? memberId : gbCommonInfo?.SiteUID
      // return `recommendPanel_${userKey}`
      const pageStore = 'page_store'
      const { store_code, originalUrl = '' } = this.catInfo || {}
      let path = originalUrl?.split('?')?.[0] || ''
      const isStore = name == pageStore // 店铺页
      const suffix = isStore ? `${pageStore}_${store_code}` : path
      return `recommendPanel_${suffix}`
    },
    // 获取localStorage缓存
    getRecoPanelLocalStorage() {
      return JSON.parse(localStorage.getItem(this.getRecoPanelStorageKey()) || '{}')
    },
    // 设置localStorage缓存
    setRecoPanelLocalStorage(params = {}) {
      return localStorage.setItem(this.getRecoPanelStorageKey(), JSON.stringify(params))
    },
    // 获取sessionStorage缓存
    getRecoPanelSessionStorage() {
      return JSON.parse(sessionStorage.getItem(this.getRecoPanelStorageKey()) || '{}')
    },
    // 设置sessionStorage缓存
    setRecoPanelSessionStorage(params) {
      return sessionStorage.setItem(this.getRecoPanelStorageKey(), JSON.stringify(params))
    },
    // 用户点击关闭实时反馈推荐面板
    closeRecoPanelByUser() {
      const dayNow = dayjs()
      const { firstTime, secondTime } = this.getRecoPanelLocalStorage()

      // 已关闭2次
      if (secondTime) {
        const dayDiff = dayNow.diff(dayjs(secondTime), 'hour')
        // 第二次关闭时间距离现在超过24小时, 缓存当前时间为第一次关闭时间
        dayDiff >= 24 && this.setRecoPanelLocalStorage({ firstTime: dayNow.valueOf() })

        return
      }

      // 仅关闭1次
      if (firstTime) {
        const dayDiff = dayNow.diff(dayjs(firstTime), 'hour')
        
        if (dayDiff >= 24) {
          // 第一次关闭时间距离现在超过24分钟, 缓存当前时间为第1次关闭时间
          this.setRecoPanelLocalStorage({ firstTime: dayNow.valueOf() })
        } else {
          // 第一次关闭时间距离现在未超过24分钟, 缓存当前时间为第二次关闭时间
          this.setRecoPanelLocalStorage({ firstTime, secondTime: dayNow.valueOf() })
        }

        return
      }

      // 未关闭过
      this.setRecoPanelLocalStorage({ firstTime: dayNow.valueOf() })
    },
    // 点击加车等其他操作自动关闭推荐面板
    autoCloseRecoPanel() {
      this.activePanel && (this.activePanel.isActive = false)
      this.activePanel = null
    },
    // 重置推荐面板相关数据
    resetRecoPanel() {
      this.recoPanelOperationConfig = {
        isCarSuccess: false,
        isFavSuccess: false,
      }
      // debugger
      // if (this.recoItem === item) {
      //   return 
      // } 
      
      // this.recoItem = item
      // this.recoGoods = []
      // if (
      //   this.isShowRecoPanelByConfig()
      //   && this.isShowRecoPanelByMostOccurrences()
      //   && this.isShowByUserClose()
      // ) {
      //   this.getRecoGoods(item, this.recoPanelConfig)
      // }
      
    },
    /**
     * 展示实时反馈推荐面板
     * @param {*} item 
     * @returns 
     */
    async showRecoPanel (item) {
      // 加车会重复触发，防止重复请求数据
      const now = Date.now()
      if (now - this.showRecoPanelTime < 1000) return
      this.showRecoPanelTime = now

      const [ recoPanel ] = this.getRecoPanel(item) || []
      if (!recoPanel) return

      const flag = this.isShowRecoPanel(item)

      if (flag) {
        this.autoCloseRecoPanel()
        this.activePanel = recoPanel
        recoPanel.show(this.activeFeedRecoRule.triggerEvent)
        // 缓存展示次数
        const { total = 0 } = this.getRecoPanelSessionStorage()
        this.setRecoPanelSessionStorage({ total: total + 1 })
      }
    },
    // 是否展示实时反馈推荐面板
    isShowRecoPanel(item) {
      return this.isShowRecoPanelByConfig()
      && this.isShowRecoPanelByMostOccurrences()
      && this.isShowByUserClose()
      && this.isShowRecoPanelByRules(item)
    },
    // 根据多配置规则rules
    isShowRecoPanelByRules (item) { 
      let index = 0
      let activeRule = null
      const { rules = [], recommendationForm } = this.cccConfig?.feedReco || {}
      let rule = rules[0] || this.cccConfig?.feedReco || {}
      while (!activeRule && index < rules.length && recommendationForm === 'productRecommend') {
        if (this.isShowRecoPanelByTriggerEvent(rule)) {
          activeRule = rule
          break
        } else {
          index += 1
          rule = rules[index]
        }

      }

      if (!activeRule) return false
      this.activeFeedRecoRule = activeRule
      const flag = this.isShowRecoPanelByRecoGoods(item)

      return flag
    },
    // 根据配置是否为空判断
    isShowRecoPanelByConfig() {
      const flag = !isEmpty(this.recoPanelConfig)

      return flag
    },
    // 根据展示频次判断
    isShowRecoPanelByMostOccurrences() {
      const { mostOccurrences } = this.recoPanelConfig
      const { total = 0 } = this.getRecoPanelSessionStorage()
      const flag  = mostOccurrences > total

      return flag
    },
    // 根据触发时机判断
    isShowRecoPanelByTriggerEvent(rule) {
      const { triggerEvent } = rule
    
      // 不判断是否加车/收藏
      if (triggerEvent === 'NotJudged') return true
      const { isCarSuccess, isFavSuccess } = this.recoPanelOperationConfig

      let flag = true

      switch(triggerEvent) {
        case 'AddCarFailFavSuccess':
          flag = !isCarSuccess && isFavSuccess
          break
        case 'AddCarFailFavFail':
          flag =  !isCarSuccess && !isFavSuccess
          break
        case 'AddCarSuccessFavSuccess':
          flag =  isCarSuccess && isFavSuccess
          break
        case 'AddCarSuccessFavFail':
          flag =  isCarSuccess && !isFavSuccess
          break
      }

      return flag
    },
    // 根据推荐商品个数判断
    // isShowRecoPanelByRecoGoods(item) {
    isShowRecoPanelByRecoGoods() {
      const { productImpressions } = this.activeFeedRecoRule
      if(!productImpressions) return true
      // this.recoGoods.length === 0 && await this.getRecoGoods(item)
      const flag = productImpressions <= this.recoGoods.length

      return flag
    },
    // 根据用户关闭推荐位判断
    isShowByUserClose() {
      const dayNow = dayjs()
      const { firstTime, secondTime } = this.getRecoPanelLocalStorage()

      // 已关闭2次
      if (secondTime) {
        const dayDiff = dayNow.diff(dayjs(secondTime), 'hour')
        if(dayDiff >= 24) {
          // 第二次关闭时间距离现在超过24分钟, 计数归0
          this.setRecoPanelLocalStorage()
          return true
        } else {
          return false
        }

      }

      // 仅关闭1次
      if (firstTime) {
        const dayDiff = dayNow.diff(dayjs(firstTime), 'hour')
        
        if (dayDiff >= 24) {
          // 第一次关闭时间距离现在超过24分支, 计数归0
          this.setRecoPanelLocalStorage()
          return true
        } else {
          // 第一次关闭时间距离现在未超过24分钟
          return true
        }
      }

      return true

    },
    // 获取推荐商品列表&商品信息
    async getRecoGoods (goods) {
      if(!goods?.goods_id) {
        return 
      }
      let feedBackData = getFeedbackRecDataInSession(goods.goods_id)
      if(!this.isShowRecoPanelByConfig() || !this.isShowRecoPanelByMostOccurrences() || !this.isShowByUserClose()) {
        return
      }
      
      if(feedBackData) {
        this.recoGoods = feedBackData
        return 
      }
      // 处理会员价相关问题
      const { isPaid } = this.sheinClubInfo || {}
      let data = {
        scene_id: 156,
        location: 'RealtimeFeedbackJson',
        limit: Number(this.recoPanelConfig.productImpressions || 0 ) + 10,
        jsonRuleId: JSON.stringify(feedbackAbtToRuleId(this.listAbtResult, this.activeFeedRecoRule.triggerEvent)),
        newPreHandle: true,
        isPaid,
        preGoodsHandlerConfig: {
          hideSuggestedSalePrice: true
        },
        contextParams: {
          goods_ids: goods.goods_id,
          cate_id: goods.cat_id,
          discount_rate: (goods.unit_discount / 100) || 0,
          goods_price: goods.salePrice?.usdAmount || '',
          click_nav_attr: getQueryString({ key: 'attr_ids' }) || ''
        },
        atomScene: {
          pageKey: 'page_real_time_feedback'
        },
        atomFields: {
          baseInfo: true, 
          prices: true,
          stock: true,
          realTimePricesWithPromotion: true,
          vipDiscountPrices: true,
          detailImage: true,
          sheinClubPromotionInfo: true,
          newFlashPromotion: true,
          ...this.getFieldsbyAbt()
        }
      }
      let useBffApi = this.usePanelBffApi?.['recomPop_v1']
      if(useBffApi) {
        const result = await schttp({
          url: '/product/recommend/feedback_products',
          method: 'GET',
          params: {
            limit: Number(this.recoPanelConfig?.productImpressions || 0 ) + 10,
            triggerEvent: this.recoPanelConfig?.triggerEvent,
            goods_id: goods.goods_id,
            goods_cate_id: goods.cat_id,
            goods_discount: (goods.unit_discount / 100) || 0,
            // discount_rate: (goods.unit_discount / 100) || 0,
            goods_price: goods.salePrice?.usdAmount || '',
            filter: getQueryString({ key: 'attr_ids' }) || ''
          },
          useBffApi: true,
          // headers: {
          //   // 'sheinmock': '{"/abt-front-gray/api/abt":{"code":0,"info":{"cid":"cf8fb68267878896425104931de0ed10","pos":{"GoodsSearch":{"bid":"","child":[],"eid":0,"param":{"listgoodssrc_word_recall":"detail:1|catehot1:10","listgoodssrc_word_rerank":"cateShuffle:2_2","listgoodssrc_word_limit":"1"},"type":"","version":"v4"}},"ugid":null,"uid":"4863579910"},"msg":"","traceId":"c647f229915c33ff"}}',
          //   'sheindebug': 676,
          //   'Content-Type': 'multipart/form-data',
          // },
        })
        // 过滤售罄商品 && 商品数据处理
        let products = (result?.info?.products || [])
          .filter(item => +item.stock !== 0 && +item.is_on_sale !== 0)
        products.forEach((item, index) => this.dealGood(item, index))
        // 中间层融合商卡数据转换器
        this.recoGoods = (products || []).map(v => {
          return goodsTransformation(v, {
            language: this.language,
            lang: this?.lang ?? 'us',
            isPaid: !!isPaid
          })
        })
        setFeedbackRecDataInSession(goods.goods_id, products)
        return
      }

      const result = await schttp({
        method: 'POST',
        url: '/api/recommend/facadeAtom/get',
        data
      })

      // 过滤售罄商品 && 商品数据处理
      let products = (result?.info?.products || [])
        .filter(item => item.is_on_sale)

      await this.recoGoodsItemInfo.getProductInfo({
        goods: products, 
        requestFields: {},
        itemConfig: this.panelItemShowConfig,
      })
      products.forEach((item, index) => this.dealGood(item, index))
      this.recoGoods = products
      setFeedbackRecDataInSession(goods.goods_id, products)
    },
    getFieldsbyAbt() {
      const { EstimatedPrice } = this.listAbtResult || {}
      const estimatedPrice = EstimatedPrice?.p?.E_Price_Cal
      const showEstimatedPriceReachThreshold = estimatedPrice === 'Have_Threshold' // 到手价（满足门槛）
      const showEstimatedPrice = estimatedPrice === 'Non_Threshold' // 到手价（满足门槛和未满足门槛）
      return {
        estimatedPrice: showEstimatedPriceReachThreshold || showEstimatedPrice // 到手价
      }
    },
    dealGood(item, index) {
      item.index = index

      const {
        src_tab_page_id,
        attr_ids = '',
      } = this.catInfo || {}

      let biQueryParams = {
        attr_ids,
        src_module: 'feedback',
        src_identifier: `on=${item.goods_id}`,
        src_tab_page_id,
      }

      const mallCode = item.mall_code ? { mallCode: item.mall_code } : {}
      this.recoGoodsItemInfo.dealGoodsDetailUrl({
        goodsItem: item,
        query: {
          ...biQueryParams,
          ...mallCode
        },
      })
      if (!this.$isServer) {
        item.relatedColor &&
          item.relatedColor.forEach((relateColorItem) => {
            const mallCode = relateColorItem.mall_code
              ? { mallCode: relateColorItem.mall_code }
              : {}
            relateColorItem.index = index
            this.recoGoodsItemInfo.dealGoodsDetailUrl({
              goodsItem: relateColorItem,
              query: {
                ...biQueryParams,
                ...mallCode
              },
            })
          }) 
        this.goodsMap[item.goods_id] = item
      }
      index += 1
    },
    // 商品条目是否售罄
    isProductSoldOut(product) {
      const { stock, attrValueId, sizeStock, sku_code } = product
      const _stock = sku_code || +attrValueId ? sizeStock : stock
      return +product.is_on_sale === 0 || +_stock === 0
    },
    // 获取找相似弹窗的推荐数据
    async getSimilarRecommendData({ wishlist = [], abtResult = {} }) {
      const { WishlistsoldoutSimilar } = abtResult ? abtResult : await getUserAbtData()
      // 未命中找相似实验、RW不展示找相似弹窗
      if (!wishlist.length || WishlistsoldoutSimilar?.p?.ShowWishlistSimilar !== 'show' || IS_RW) return
      const requestIds = []
      wishlist.forEach(product => {
        if (this.isProductSoldOut(product)) {
          // 将8个推荐数据分成一组，去批量请求推荐数据，
          // 为什么是八个？考虑推荐接口的性能问题，超过八个推荐接口不返回数据
          if (!requestIds.length || requestIds[requestIds.length - 1].length >= 8) {
            requestIds.push([{
              goods_id: product.goods_id,
              cat_id: product.cat_id
            }])
          } else {
            requestIds[requestIds.length - 1].push({
              goods_id: product.goods_id,
              cat_id: product.cat_id
            })
          }
        }
      })
      requestIds.forEach((ids) => {
        this.getBatchRecommend({ goodsIds: ids.map(item => item.goods_id).join(','), cateIds: ids.map(item => item.cat_id).join(',') }, abtResult)
      })
    },
    async getBatchRecommend({ goodsIds, cateIds }, abtResult) {
      // 防止重复请求
      if(goodsIds === this.LastGoodsIds && cateIds === this.LastCateIds) return
      this.LastGoodsIds = goodsIds
      this.LastCateIds = cateIds
      const { Similaritems } = abtResult ? abtResult : await getUserAbtData()
      const abt = Similaritems?.p || ''
      const abtRes = abt.match(/rule_id=([^&]+)/)
      const ruleId = abtRes && abtRes.length ? abtRes?.[1] : ''
      let data = {
        scene_id: 18,
        rule_id: ruleId,
        limit: 160,
        resultType: 'batch',
        contextParams: {
          goods_id: goodsIds,
          cate_id: cateIds,
          batch_size_per_goods: 20
        },
        newPreHandle: true,
        preGoodsHandlerConfig: {
          hideSuggestedSalePrice: true
        },
        atomFields: {
          baseInfo: true, 
          prices: true,
          stock: true,
          estimatedPrice: true,
          realTimePricesWithPromotion: true,
          vipDiscountPrices: true,
          detailImage: true,
          sheinClubPromotionInfo: true,
        }
      }
      const result = await schttp({
        method: 'POST',
        url: '/api/recommend/facadeAtom/get',
        data,
      })
      // 过滤售罄商品 && 商品数据处理
      let products = (result?.info?.products || [])
        .filter(item => item.is_on_sale)
      products.map((item, index) => {
        item.index = index
        const mallCode = item.mall_code ? { mallCode: item.mall_code } : {}
        this.goodsItemInfo &&
          this.goodsItemInfo.dealGoodsDetailUrl({
            goodsItem: item,
            query: {
              ...mallCode,
              skuCode: item.sku_code,
            },
          })
        return item
      })
      this.updateSimilarRecommendData({ products })
    },
    updateSimilarRecommendData({ products = [] }) {
      const similarRecommendData = products.reduce((pre, cur) => {
        if (pre[cur.source_goods_id] && pre[cur.source_goods_id].length) {
          pre[cur.source_goods_id].push(cur)
        } else {
          pre[cur.source_goods_id] = [cur]
        }
        return pre
      }, {})
      this.similarRecommendData = {
        ...this.similarRecommendData,
        ...similarRecommendData,
      }
    },
  }
}
